.pagination {
  display: flex;
  flex-wrap: if($pagination-margin-start == (-$pagination-border-width), null, wrap); // Boosted mod
  margin: $pagination-margin-y 0; // Boosted mod
  @include list-unstyled();
}

.page-link {
  position: relative;
  // Boosted mod
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bold;
  // End mod
  color: $pagination-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;
  @include transition($pagination-transition);
  // Boosted mod: embed icon as mask-image
  @include button-icon(
    $pagination-icon,
    $pagination-icon-size,
    $size: $pagination-icon-width $pagination-icon-height,
    $selector: ".page-item:first-child .page-link, .page-item:last-child .page-link"
  );
  // End mod

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: $pagination-focus-color;
    background-color: $pagination-focus-bg;
    border-color: $pagination-hover-border-color; // Boosted mod
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }

  // Boosted mod
  &:active {
    color: color-contrast($pagination-active-item-bg);
    background-color: $pagination-active-item-bg;
    border-color: $pagination-active-item-border;
    outline-color: $pagination-active-item-border;
  }
  // End mod
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $pagination-margin-start;
  }

  &.active .page-link {
    z-index: 3;
    color: $pagination-active-color;
    @include gradient-bg($pagination-active-bg);
    border-color: $pagination-active-border-color;
    outline-color: $pagination-active-bg; // Boosted mod
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }

  // Boosted mod
  &:first-child .page-link,
  &:last-child .page-link {
    border-color: currentColor;

    &:hover,
    &:focus {
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
    }

    &:active {
      color: $black;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &:first-child .page-link {
    &:not(:empty) {
      padding-right: $pagination-padding-end;
    }

    &::before {
      /* rtl:raw:
      transform: scaleX(-1);
      */
    }
  }

  &:last-child .page-link {
    &:not(:empty) {
      padding-left: $pagination-padding-end;
    }

    &::before {
      order: 1;

      /* rtl:remove */
      transform: scaleX(-1);
    }
  }

  @include media-breakpoint-down(sm) {
    @include pagination-max-items(4);
  }

  @include media-breakpoint-between(sm, md) {
    @include pagination-max-items(6);
  }

  @include media-breakpoint-between(md, lg) {
    @include pagination-max-items(8);
  }
  // End mod
}


//
// Sizing
//
@include pagination-size($pagination-padding-y, $pagination-padding-x, null, $pagination-border-radius);

// Boosted mod: no pagination sizes

