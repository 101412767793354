//
// Rotating border
//

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}
// scss-docs-end spinner-border-keyframes

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: $spinner-vertical-align;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 50%;
  animation: $spinner-animation-speed linear infinite spinner-border;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

// Boosted mod
.spinner-border-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
  border-width: $spinner-border-width-lg;
}

@if $enable-reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    .spinner-border {
      animation-duration: $spinner-animation-speed * 2;
    }
  }
}
// End mod

// Boosted mod: no .spinner-grow
