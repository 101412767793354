// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #fafafa !default;
$gray-200: #f6f6f6 !default;
$gray-300: #eee !default;
$gray-400: #ddd !default;
$gray-500: #ccc !default;
$gray-600: #999 !default;
$gray-700: #666 !default;
$gray-800: #595959 !default;
$gray-900: #333 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start brand-colors
// Boosted mod
//// Core colors
$accessible-orange: #f16e00 !default;
$brand-orange:      #ff7900 !default;
//// Functional colors
$functional-green:  #32c832 !default;
$functional-blue:   #527edb !default;
$functional-yellow: #fc0 !default;
$functional-red:    #cd3c14 !default;
//// Supporting colors
$supporting-blue:   #4bb4e6 !default;
$supporting-yellow: #ffd200 !default;
$supporting-green:  #50be87 !default;
$supporting-purple: #a885d8 !default;
$supporting-pink:   #ffb4e6 !default;
// scss-docs-end brand-colors
// End mod


// scss-docs-start color-variables
$blue:    $functional-blue !default;
$indigo:  $supporting-purple !default;
$purple:  $supporting-purple !default;
$pink:    $supporting-pink !default;
$red:     $functional-red !default;
$orange:  $brand-orange !default;
$yellow:  $functional-yellow !default;
$green:   $functional-green !default;
$teal:    $supporting-green !default;
$cyan:    $supporting-blue !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $orange !default;
$secondary:     $black !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-500 !default;
$dark:          $black !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;
// scss-docs-end theme-colors-rgb

// Boosted mod
$background-colors: map-merge(
  $theme-colors,
  (
    "success": $supporting-green,
    "info":    $supporting-blue,
    "warning": $supporting-yellow,
    "danger":  $danger
  )
) !default;
// End mod

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark:  $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
) !default;

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
) !default;

$purples: (
  "purple-100": $purple-200,
  "purple-200": $purple-100,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
) !default;

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
) !default;

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
) !default;

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
) !default;

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
) !default;

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
) !default;

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
) !default;

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
) !default;
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;

// Boosted mod
//// SVG as Data-URi
$chevron-icon:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'><path d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/></svg>") !default;
$cross-icon:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='#{$black}'><path d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/></svg>") !default;
$cross-icon-stroke:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='#{$black}'><path stroke='#{$black}' stroke-width='5' stroke-linecap='round' d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/></svg>") !default;
$check-icon:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'><path fill='#{$black}' d='M13 0L5 8 2 5 0 7l5 5L15 2z'/></svg>") !default;
$burger-icon:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$black}' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$success-icon:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'><path fill='#{$success}' d='M62.5 0a62.5 62.5 0 100 125 62.5 62.5 0 000-125zm28 29.4c3.3 0 6 2.6 6 5.9a5.9 5.9 0 01-1.3 3.7L57.7 86a5.8 5.8 0 01-9.1 0L29.8 62.5c-.8-1-1.2-2.3-1.2-3.7a5.9 5.9 0 011.7-4.1l2.3-2.4a5.8 5.8 0 014.2-1.7 5.8 5.8 0 013.8 1.4L52 64.7 86.6 31a5.8 5.8 0 014-1.6z'/></svg>") !default;
$info-icon:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'><path fill='#{$info}' d='M62.5 0a62.5 62.5 0 100 125 62.5 62.5 0 000-125zm0 14.7a11 11 0 110 22 11 11 0 010-22zM47.8 44.1h25.7v46.2c0 4.7 1.3 6.5 1.8 7.2.8 1 2.3 1.5 4.8 1.6h.8v3.8H47.8v-3.7h.8c2.3-.1 4-.8 5-2 .4-.4 1-2 1-7V57c0-4.8-.6-6.6-1.2-7.3-.8-1-2.4-1.5-4.9-1.6h-.7V44z'/></svg>") !default;
$warning-icon:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='#{$warning}' d='M15 0a15 15 0 100 30 15 15 0 000-30zm.15 5.39h.01c1.12 0 2 .95 1.92 2.06l-.63 10.43c0 .7-.58.97-1.29.97-.72 0-1.28-.27-1.28-.97l-.63-10.46c-.06-1.09.8-2.01 1.9-2.03zm-.3 15.33c.11 0 .21 0 .31.02 2.19.35 2.19 3.5 0 3.84-2.77.44-3.1-3.86-.3-3.86z'/></svg>") !default;
$danger-icon:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 125'><path fill='#{$danger}' d='M70.3 0c-5.8 0-10.8 3.1-13.5 7.8L2.3 101.3l-.2.2A15.6 15.6 0 0015.6 125H125a15.6 15.6 0 0013.5-23.5L83.8 7.8A15.6 15.6 0 0070.3 0zm19.2 50a6.4 6.4 0 014.4 1.9 6.4 6.4 0 010 9L79.4 75.6l15 15a6.4 6.4 0 010 9.2 6.4 6.4 0 01-4.5 1.9 6.4 6.4 0 01-4.6-2l-15-15-15 15a6.4 6.4 0 01-4.6 2 6.4 6.4 0 01-4.6-2 6.4 6.4 0 010-9l15-15L46.8 61a6.4 6.4 0 119-9.1l14.6 14.5L84.8 52a6.4 6.4 0 014.7-1.9z'/></svg>") !default;
//// SVG used several times
$svg-as-custom-props: (
  "chevron": $chevron-icon,
  "close":   $cross-icon-stroke,
  "check":   $check-icon,
  "success": $success-icon,
  "error":   $danger-icon
) !default;
//// Filters
// see https://codepen.io/sosuke/pen/Pjoqqp
$invert-filter:         invert(1) !default;
$orange-filter:         invert(46%) sepia(60%) saturate(2878%) hue-rotate(6deg) brightness(98%) contrast(104%) !default;
// End mod

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              false !default;
$enable-shadows:              false !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  false !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: false !default;
$enable-important-utilities:  true !default;
$enable-fixed-header:         true !default; // Boosted mod: used to apply scroll-padding-top

// Prefix for :root CSS variables

$variable-prefix:             bs- !default;
$boosted-variable-prefix:     o- !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Boosted elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1.25rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps

$target-size: 2.75rem !default; // Boosted mod: minimum target size (44×44px)


// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $black !default;
$body-text-align:           null !default;

// Utilities maps
//
// Extends the default `$theme-colors` maps to help create our utilities.

// Come v6, we'll de-dupe these variables. Until then, for backward compatibility, we keep them to reassign.
// scss-docs-start utilities-colors
$utilities-colors: $theme-colors-rgb !default;
// scss-docs-end utilities-colors

// scss-docs-start utilities-text-colors
// Boosted mod: content of $utilities-text
$utilities-text: (
  "primary": to-rgb($accessible-orange),
  "light": to-rgb($gray-500),
  "white": to-rgb($white),
  "body": to-rgb($body-color),
) !default;
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text") !default;
// scss-docs-end utilities-text-colors

// scss-docs-start utilities-bg-colors
$utilities-bg: map-merge(
  $utilities-colors,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-bg)
  )
) !default;
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg") !default;
// scss-docs-end utilities-bg-colors

// Links
//
// Style anchor elements.

$link-color:                              $black !default;
$link-decoration:                         underline !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        $accessible-orange !default;
$link-hover-decoration:                   null !default;


$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;


// Boosted mod
$linked-chevron-icon-width:   subtract(.5rem, 1px) !default;
$linked-chevron-icon-height:  $spacer * .5 !default;
$linked-chevron-transform:    rotate(.5turn) translateY(1px) !default;
$linked-chevron-margin-left:  $spacer * .25 !default;
// End mod


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  xs: 312px,
  sm: 468px,
  md: 744px,
  lg: 960px,
  xl: 1200px,
  xxl: 1410px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           $spacer !default;
$grid-gutter-breakpoint:      "md" !default; // Boosted mod: gutter depends on breakpoint
$grid-row-columns:            6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: $grid-gutter-width * .25 !default;


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                .125rem !default;
$border-widths: (
  1: $border-width * .5,
  2: $border-width
) !default;

$border-color:                $black !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               null !default;
$border-radius-sm:            null !default;
$border-radius-lg:            null !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

$outline-width:               $border-width !default;  // Boosted mod
$outline-offset:              $outline-width !default; // Boosted mod

// scss-docs-start box-shadow-variables
$box-shadow:                  null !default;
$box-shadow-sm:               null !default;
$box-shadow-lg:               null !default;
$box-shadow-inset:            null !default;
// scss-docs-end box-shadow-variables

$component-active-color:      $black !default;
$component-active-bg:         $primary !default;

// scss-docs-start caret-variables
$caret-width:                 add($spacer * .25, $border-width) !default;
$caret-vertical-align:        center !default;
$caret-spacing:               $spacer * .5 !default;
// scss-docs-end caret-variables

$transition-duration: .2s !default; // Boosted mod
$transition-timing:   ease-in-out !default; // Boosted mod
$transition-base:     all $transition-duration $transition-timing !default;
$transition-fade:     opacity $transition-timing linear !default;
// scss-docs-start collapse-transition
$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;
// scss-docs-end collapse-transition
// scss-docs-start focus-transition
$transition-focus:    outline-offset $transition-duration $transition-timing !default; // Boosted mod
// scss-docs-end focus-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      HelvNeueOrange#{"/*rtl:insert:Arabic*/"}, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !default;
// stylelint-enable value-keyword-case
$font-family-base:            var(--#{$variable-prefix}font-sans-serif) !default;
$font-family-code:            var(--#{$variable-prefix}font-monospace) !default;


// Boosted mod
//// Type scale & vertical rhythm completely revamped to match Orange Web Guidelines

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;  // 14px
$font-size-lg:                $font-size-base * 1.125 !default; // 18px
$font-size-xlg:               $font-size-base * 1.25 !default;  // 20px

$font-weight-lighter:         null !default;
$font-weight-light:           null !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          null !default;

$font-weight-base:            $font-weight-normal !default;

// stylelint-disable function-disallowed-list
$line-height-base:            calc(18 / 16) !default;
$line-height-sm:              calc(16 / 14) !default;
$line-height-lg:              calc(30 / 16) !default;
// stylelint-enable function-disallowed-list
$letter-spacing-base:         $spacer * -.005 !default; // -0.1px

$h1-font-size:                $font-size-base * 2.125 !default;   // 34px
$h2-font-size:                $font-size-base * 1.875 !default;   // 30px
$h3-font-size:                $font-size-base * 1.5 !default;     // 24px
$h4-font-size:                $font-size-xlg !default;            // 20px
$h5-font-size:                $font-size-lg !default;             // 18px
$h6-font-size:                $font-size-base !default;           // 16px
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;
// scss-docs-end font-sizes

// scss-docs-start letter-spacing
$h1-spacing:                  $letter-spacing-base * 10 !default; // -1px
$h2-spacing:                  $letter-spacing-base * 8 !default;  // -0.8px
$mid-spacing:                 $letter-spacing-base * 6 !default;  // -0.6px
$h3-spacing:                  $letter-spacing-base * 5 !default;  // -0.5px
$h4-spacing:                  $letter-spacing-base * 4 !default;  // -0.4px
$h5-spacing:                  $letter-spacing-base * 2 !default;  // -0.2px
$h6-spacing:                  $letter-spacing-base !default;
// scss-docs-end letter-spacing

// stylelint-disable function-disallowed-list
// scss-docs-start line-height
$h1-line-height:              1 !default;
$h2-line-height:              calc(32 / 30) !default;
$h3-line-height:              calc(26 / 24) !default;
$h4-line-height:              calc(22 / 20) !default;
$h5-line-height:              calc(20 / 18) !default;
$h6-line-height:              $line-height-base !default;
// scss-docs-end line-height
// stylelint-enable function-disallowed-list

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        700 !default;
$headings-line-height:        $h6-line-height !default;
$headings-color:              null !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display1-size:               $font-size-xlg * 3 !default;        // 60px
$display2-size:               $font-size-xlg * 2.5 !default;      // 50px
$display3-size:               $font-size-xlg * 2 !default;        // 40px
$display4-size:               $h1-font-size !default;             // 34px
$display1-spacing:            $letter-spacing-base * 20 !default; // -2px
$display2-spacing:            $letter-spacing-base * 16 !default; // -1.6px
$display3-spacing:            $h1-spacing !default;               // -1px
$display4-spacing:            $h1-spacing !default;               // -1px
$display-line-height:         $h1-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              $font-size-xlg !default;
$lead-font-weight:            400 !default;
$lead-line-height:            1.5 !default;
$lead-letter-spacing:         $letter-spacing-base * 2.5 !default;

$small-font-size:             .875rem !default;

$sub-sup-font-size:           .75em !default;

$text-muted:                  $gray-700 !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-xlg !default;
$blockquote-footer-color:     $gray-700 !default;
$blockquote-footer-font-size: $small-font-size !default;
$blockquote-line-height:      1.5 !default; // Boosted mod
$blockquote-letter-spacing:   $letter-spacing-base * .25 !default; // Boosted mod

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;
$hr-height:                   $border-width !default;
$hr-opacity:                  null !default;

$legend-margin-bottom:        $spacer * .25 !default;
$legend-font-size:            $font-size-xlg !default;
$legend-font-weight:          $font-weight-bold !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         $spacer * .25 !default;

$mark-bg:                     $primary !default;
// scss-docs-end type-variables
// End mod

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:      .875rem !default;
$table-cell-padding-x:      $spacer * .5 !default;
$table-cell-padding-y-sm:   .5625rem !default;
$table-cell-padding-x-sm:   $table-cell-padding-x !default;

$table-cell-vertical-align:   top !default;
$table-line-height:           1.25 !default; // Boosted mod

$table-color:                 $body-color !default;
$table-bg:                    $body-bg !default;
// Boosted mod: no $table-accent-bg

$table-th-font-weight:        null !default;

$table-active-color:          $table-color !default;
$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($black, $table-active-bg-factor) !default;

$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .065 !default;
$table-hover-bg:              rgba($black, $table-hover-bg-factor) !default;

$table-border-factor:         .4 !default;
$table-border-width:          $border-width * .5 !default;
$table-border-color:          $gray-500 !default;

$table-group-separator-color: currentColor !default;

$table-caption-color:         $black !default;
$table-caption-padding-y:     .75rem !default; // Boosted mod
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "dark": $dark
) !default;
// scss-docs-end table-loop

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .5rem !default;
$input-btn-padding-x:         1.125rem !default;
$input-btn-font-family:       inherit !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       1.25 !default;

$input-btn-focus-width:       .125rem !default;
// Boosted mod: no need for a blur variable
$input-btn-focus-box-shadow:  null !default;

$input-btn-padding-y-sm:      $spacer * .25 !default;
$input-btn-padding-x-sm:      $spacer * .5 !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .8125rem !default;
$input-btn-padding-x-lg:      $spacer !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      $border-width !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Boosted's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $line-height-sm !default; // Boosted mod

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $h5-line-height !default; // Boosted mod

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-bold !default;
$btn-box-shadow:              null !default;
$btn-focus-width:             $border-width !default;
$btn-focus-box-shadow:        0 0 0 $btn-focus-width $white !default;
$btn-disabled-opacity:        1 !default;
$btn-active-box-shadow:       null !default;

$btn-link-color:              $link-color !default;
$btn-link-hover-color:        $link-hover-color !default;
$btn-link-disabled-color:     $gray-500 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              $transition-focus !default;
// scss-docs-end btn-variables

// Boosted mod: icon button
$btn-icon-padding-x:          subtract($spacer * .5, $border-width) !default;
$btn-icon-padding-x-sm:       $spacer * .25 !default;
$btn-icon-padding-x-lg:       add($spacer * .5, $border-width * 1.5) !default;
// Boosted mod: social button
// scss-docs-start social-buttons
$btn-social-networks: (
  "facebook": (
    "color": #3b5998,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M19 6h5V0h-5c-4 0-7 3-7 7v3H8v6h4v16h6V16h5l1-6h-6V7l1-1z'></path></svg>"
  ),
  "twitter": (
    "color": #1da1f2,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M32 7a13 13 0 01-3.8 1.1 6.6 6.6 0 003-3.6c-1.4.7-2.8 1.3-4.3 1.6a6.6 6.6 0 00-11.1 6A18.6 18.6 0 012.2 5a6.6 6.6 0 002 8.9c-1 0-2-.4-3-.9v.1c0 3.2 2.4 5.9 5.4 6.5a6.6 6.6 0 01-3 0 6.6 6.6 0 006.1 4.6A13.2 13.2 0 010 27.1a18.6 18.6 0 0028.7-16.6C30 9.5 31.1 8.4 32 7z'/></svg>"
  ),
  "instagram": (
    "color": #e1306c,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M16 2.9h6.5c1.5.1 2.4.4 3 .6a5 5 0 011.8 1.2c.5.6.9 1.1 1.2 1.9.2.5.4 1.4.5 3a112.7 112.7 0 01-.5 15.8 5 5 0 01-1.2 1.9c-.6.5-1.1.9-1.9 1.2-.5.2-1.4.4-3 .5a112.7 112.7 0 01-15.8-.5 5 5 0 01-1.9-1.2 5 5 0 01-1.2-1.9c-.2-.5-.4-1.4-.5-3a112.7 112.7 0 01.5-15.8 5 5 0 011.2-1.9c.6-.5 1.1-.9 1.9-1.2C7 3.3 8 3 9.6 3l6.4-.1zM16 0H9.4C7.7.3 6.5.5 5.5.9s-2 1-2.8 1.9c-1 .9-1.5 1.8-1.9 2.8-.4 1-.6 2.2-.7 3.9a117.6 117.6 0 00.7 17c.5 1.1 1 2 1.9 3 .9.8 1.8 1.4 2.8 1.8 1 .4 2.2.6 3.9.7a117.2 117.2 0 0017-.7c1.1-.4 2-1 2.9-1.9s1.4-1.8 1.8-2.8c.4-1 .7-2.2.8-3.9a117.2 117.2 0 00-.8-17A7.8 7.8 0 0026.4.8c-1-.5-2.1-.7-3.8-.8L16 0z'/><path d='M16 7.8a8.2 8.2 0 100 16.4 8.2 8.2 0 000-16.4zm0 13.5a5.3 5.3 0 110-10.6 5.3 5.3 0 010 10.6zM26.5 7.5a2 2 0 11-3.9 0 2 2 0 013.9 0z'/></svg>"
  ),
  "youtube": (
    "color": #f00,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M31.7 9.6s-.3-2.2-1.3-3.2c-1.2-1.3-2.6-1.3-3.2-1.3-4.5-.4-11.2-.4-11.2-.4s-6.7 0-11.2.4c-.6 0-2 0-3.2 1.3C.6 7.4.3 9.6.3 9.6S0 12.2 0 14.8v2.4c0 2.6.3 5.2.3 5.2s.3 2.2 1.3 3.2c1.2 1.2 2.8 1.2 3.5 1.3 2.6.3 11 .4 11 .4s6.6 0 11.1-.4c.6 0 2 0 3.2-1.3 1-1 1.3-3.2 1.3-3.2s.3-2.6.3-5.2v-2.4c0-2.6-.3-5.2-.3-5.2zm-19 10.5v-9l8.6 4.6-8.6 4.4z'/></svg>"
  ),
  "linkedin": (
    "color": #0077b5,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M12 12h5.5v2.8h.1a6.1 6.1 0 015.5-2.8c5.8 0 6.9 3.6 6.9 8.4V30h-5.8v-8.5c0-2 0-4.7-3-4.7s-3.4 2.2-3.4 4.5V30H12V12zM2 12h6v18H2V12zm6-5a3 3 0 11-6 0 3 3 0 016 0z'/></svg>",
  ),
  "whatsapp": (
    "color": #25d366,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M27.3 4.7a15.9 15.9 0 00-25 19.1L.1 32l8.4-2.2A15.9 15.9 0 0027.3 4.7zM16 29c-2.4 0-4.7-.6-6.7-1.8l-.5-.3-5 1.3 1.3-4.8-.3-.5A13.2 13.2 0 1116.1 29zm7.2-9.8l-2.7-1.3c-.3-.1-.6-.2-1 .2l-1.2 1.5c-.2.3-.4.3-.8.1s-1.7-.6-3.2-2c-1.2-1-2-2.3-2.2-2.7s0-.6.2-.8l.6-.7.4-.6v-.7l-1.3-3c-.3-.7-.6-.6-.9-.7h-.7c-.2 0-.7.1-1.1.5C9 9.4 8 10.4 8 12.3s1.4 3.9 1.6 4.1c.2.3 2.8 4.3 6.8 6l2.3.9c.9.3 1.8.2 2.4.1.8-.1 2.4-1 2.7-1.9s.4-1.7.3-1.9l-.8-.4z'/></svg>"
  ),
  "mail": (
    "color": $primary,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M3.2 14.3c0 9.5 0 9 .2 9.5.3.8 1 1.4 1.7 1.7l12.2.1h11.5v-8.8c0-9.3 0-8.9-.2-9.3-.2-.7-.7-1.2-1.3-1.6l-.8-.3H3.2v8.7zm22.9-2.4a246.2 246.2 0 01-4.9 4.7l-.8.7-.5.6-.7.6c-.6.6-1 .9-1.3 1a4 4 0 01-1.8.5 4 4 0 01-2.4-.6 13 13 0 01-1.9-1.7l-2.4-2.4-.6-.6-1.4-1.3L6.1 12l-.5-.5V8.9l.6.5L7.9 11l1.4 1.4 1.3 1.2 1.3 1.3a195 195 0 012.6 2.4c.4.3 1 .5 1.6.4.5 0 1-.1 1.4-.4L19 16l1-1 1-1a214.7 214.7 0 012.2-2l1-1 2-2 .2-.2v2.8l-.3.3z'/></svg>",
    "size": 1.5rem
  ),
  "snapchat": (
    "color": #fffc00,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 28'><path d='M13 2c3 0 5 2 7 4v6h2l1 1-3 2v1l4 4h1l1 1-4 1-1 2h-2-1c-1 0-2 2-5 2s-4-2-5-2H5l-1-2-4-1 1-1h1l4-4v-1l-3-2 1-1h2V9 6c2-3 4-4 7-4z'/></svg>"
  ),
  "pinterest": (
    "color": red,
    "icon": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M16 2a14 14 0 00-5 27v-4l2-7-1-2c0-2 1-3 3-3l1 2-1 4c0 2 1 3 2 3 3 0 5-3 5-7 0-3-3-5-6-5-4 0-6 3-6 6l1 3a302 302 0 01-1 2c-2-1-3-3-3-5 0-5 3-9 9-9 5 0 9 4 9 8 0 5-3 9-7 9l-4-2v4l-2 3a14 14 0 0018-13c0-8-6-14-14-14z'/></svg>",
    "size": 1.375rem
  )
) !default;
// scss-docs-end social-buttons
// End mod

// Forms

// scss-docs-start form-text-variables
$form-text-margin-top:                  .25rem !default;
$form-text-font-size:                   null !default;
$form-text-font-style:                  null !default;
$form-text-font-weight:                 null !default;
$form-text-color:                       null !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom:              .375rem !default;
$form-label-font-size:                  null !default;
$form-label-font-style:                 null !default;
$form-label-font-weight:                $font-weight-bold !default;
$form-label-color:                      null !default;
$form-label-disabled-color:             $gray-500 !default; // Boosted mod
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $spacer * .5 !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-bold !default;
$input-line-height:                     $input-btn-line-height !default;

// Boosted mod: no input-sm

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              $body-bg !default;
$input-disabled-bg:                     $gray-300 !default;
$input-disabled-color:                  $gray-700 !default; // Boosted mod
$input-disabled-border-color:           null !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-500 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius:                   $border-radius !default;
// Boosted mod: no input-sm
$input-border-radius-lg:                $border-radius-lg !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              currentColor !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-700 !default;
$input-plaintext-color:                 $body-color !default;

// Boosted mod: no $input-height-inner-*

$input-height:                          2.5rem !default;
// Boosted mod: no input-sm
$input-height-lg:                       3.125rem !default;
$input-line-height-lg:                  $h5-line-height !default; // Boosted mod

$input-transition:                      border-color $transition-duration $transition-timing, $transition-focus !default;

$form-color-width:                      3rem !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width:                  1em !default;
$form-check-min-height:                   $font-size-base * $input-btn-line-height !default;
$form-check-padding-start:                $form-check-input-width + .5em !default;
$form-check-margin-bottom:                .125rem !default;
$form-check-label-color:                  null !default;
$form-check-label-cursor:                 null !default;
$form-check-transition:                   null !default;

$form-check-input-active-filter:          null !default;
$form-check-input-active-bg-color:        $component-active-bg !default; // Boosted mod

$form-check-input-bg:                     $input-bg !default;
$form-check-input-border:                 $border-width solid $input-border-color !default;
$form-check-input-border-radius:          0 !default;
$form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border:           null !default;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow !default;

$form-check-input-checked-color:          $component-active-color !default;
$form-check-input-checked-bg-color:       $component-active-bg !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:       var(--#{$boosted-variable-prefix}check-icon) !default;
$form-check-input-disabled-color:         $gray-900 !default; // Boosted mod
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color:          color-contrast($component-active-color) !default;
$form-check-input-indeterminate-bg-color:       $component-active-color !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'><path fill='#{$form-check-input-indeterminate-color}' d='M0 0h10v3H0z'/></svg>") !default;

$form-check-input-disabled-opacity:        null !default;
$form-check-label-disabled-opacity:        null !default;
$form-check-btn-check-disabled-opacity:    null !default;

$form-check-inline-margin-end:    1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-width:               $spacer * 3 !default;
$form-switch-padding-start:       $form-switch-width + .5rem !default;
// Boosted mod
$form-switch-bg-image:            var(--#{$boosted-variable-prefix}close-icon) !default;
$form-switch-bg-position:         right .5rem top 50% !default;
$form-switch-bg-size:             .75rem !default;
$form-switch-bg-square-size:      add(1rem, $spacer * .5) !default;
// End mod
$form-switch-border-radius:       null !default;
$form-switch-transition:          background-position .15s ease-in-out, $transition-focus !default;

$form-switch-checked-bg-image:    $form-check-input-checked-bg-image !default;
$form-switch-checked-bg-size:     add(map-get($spacers, 2), map-get($spacers, 1)) !default;
$form-switch-checked-bg-position: $border-width * 3 50% !default;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y:           $input-padding-y !default;
$input-group-addon-padding-x:           $input-padding-x !default;
$input-group-addon-font-weight:         $input-font-weight !default;
$input-group-addon-color:               null !default;
$input-group-addon-bg:                  null !default;
$input-group-addon-border-color:        null !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y:             $input-padding-y !default;
$form-select-padding-x:             $input-padding-x !default;
$form-select-font-family:           $input-font-family !default;
$form-select-font-size:             $input-font-size !default;
$form-select-indicator-padding:     $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight !default;
$form-select-line-height:           $input-line-height !default;
$form-select-color:                 $input-color !default;
$form-select-bg:                    $input-bg !default;
$form-select-disabled-color:        $gray-700 !default;
$form-select-disabled-bg:           $gray-300 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position:           right $form-select-padding-x top add(50%, 1px) !default;
$form-select-bg-size:               .875rem 1rem !default; // In pixels because image dimensions
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'><path d='M7 7L0 0h14L7 7z'/></svg>") !default;
$form-select-disabled-indicator:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'><path fill='#{$form-select-disabled-color}' d='M7 7L0 0h14L7 7z'/></svg>") !default; // Boosted mod

// Boosted mod: no icon for validated select

$form-select-border-width:        $input-border-width !default;
$form-select-border-color:        $input-border-color !default;
$form-select-border-radius:       $border-radius !default;
$form-select-box-shadow:          $box-shadow-inset !default;

$form-select-focus-border-color:  $input-focus-border-color !default;
$form-select-focus-box-shadow:    null !default;

// Boosted mod: no .form-select-sm

$form-select-padding-y-lg:        $spacer * .5 !default;
$form-select-padding-x-lg:        $input-padding-x-lg !default;
$form-select-font-size-lg:        $input-font-size-lg !default;

$form-select-transition:          $input-transition !default;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width:          100% !default;
$form-range-track-height:         .375rem !default;
$form-range-track-cursor:         pointer !default;
$form-range-track-bg:             $gray-300 !default;
$form-range-track-filled-bg:      $primary !default; // Boosted mod
$form-range-track-border-radius:  null !default;
$form-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$form-range-thumb-width:                   1rem !default;
$form-range-thumb-height:                  $form-range-thumb-width !default;
$form-range-thumb-bg:                      $white !default;
$form-range-thumb-border:                  $border-width solid $black !default;
$form-range-thumb-border-radius:           50% !default;
$form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
$form-range-thumb-focus-box-shadow:        null !default;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               null !default;
$form-range-thumb-active-border:           $component-active-bg !default; // Boosted mod
$form-range-thumb-disabled-bg:             $gray-500 !default;
$form-range-thumb-transition:              background-color $transition-duration $transition-timing, border-color $transition-duration $transition-timing, box-shadow $transition-duration $transition-timing !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color:          $input-color !default;
$form-file-button-bg:             $white !default;
$form-file-button-hover-bg:       shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables

// Boosted mod: no floating labels

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $small-font-size !default;
$form-feedback-font-style:          null !default;
$form-feedback-valid-color:         $success !default;
$form-feedback-invalid-color:       $danger !default;

$form-feedback-icon-valid:          var(--#{$boosted-variable-prefix}success-icon) !default;
$form-feedback-icon-invalid:        var(--#{$boosted-variable-prefix}error-icon) !default;
$form-feedback-icon-size:           add($spacer * .25, $spacer * .5) !default; // Boosted mod
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-back-to-top:                1035 !default; // Boosted mod
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                $spacer * .5 !default;
$nav-link-padding-x:                $spacer !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              $font-weight-bold !default;
$nav-link-color:                    $link-color !default;
$nav-link-hover-color:              $link-hover-color !default;
$nav-link-transition:               null !default;
$nav-link-disabled-color:           $gray-500 !default;

$nav-tabs-border-color:             $black !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $black !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-color !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  add($spacer, $spacer * .25) !default;
$navbar-padding-x:                  null !default;
$navbar-padding-bottom:             add($spacer * .25, $border-width) !default; // Boosted mod

$navbar-nav-link-padding-x:         $nav-link-padding-x * .5 !default;

// Boosted mod: Orange master logo & minimizing behaviour
$navbar-icon-size:                     $spacer * 1.5 !default;
//$navbar-height:                        6.25rem !default;
$navbar-brand-logo-height:             add($spacer * 1.5, $spacer) !default;
$navbar-brand-logo-minimized-height:   $spacer * 1.5 !default;
//$navbar-supra-padding-y:               $navbar-brand-logo-height / 10 !default;
//$navbar-supra-padding-x:               $spacer * .25 * 1.5 !default;
$navbar-transition-duration:           $transition-duration !default;
$navbar-transition-timing-function:    $transition-timing !default;
$navbar-transition:                    padding-top $navbar-transition-duration $navbar-transition-timing-function, padding-bottom $navbar-transition-duration $navbar-transition-timing-function, $transition-focus !default;
$navbar-brand-transition:              margin $navbar-transition-duration $navbar-transition-timing-function, $transition-focus !default;
$navbar-brand-logo-transition:         width $navbar-transition-duration $navbar-transition-timing-function, height $navbar-transition-duration $navbar-transition-timing-function !default;
$navbar-active-transition:             bottom $navbar-transition-duration $navbar-transition-timing-function !default;
// End mod

$navbar-brand-font-size:            $font-size-base !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$navbar-brand-padding-y:            null !default;
$navbar-brand-margin-end:           $spacer * .25 !default;

$navbar-toggler-padding-y:          .5rem !default;
$navbar-toggler-padding-x:          .5rem !default;
$navbar-toggler-font-size:          $font-size-base !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        null !default;
$navbar-toggler-transition:         $transition-focus !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color:                  $white !default;
$navbar-dark-hover-color:            $primary !default;
$navbar-dark-active-color:           $primary !default;
$navbar-dark-disabled-color:         $gray-400 !default;
$navbar-dark-toggler-border-color:   transparent !default;

$navbar-light-color:                 $black !default;
$navbar-light-hover-color:           $accessible-orange !default;
$navbar-light-active-color:          $accessible-orange !default;
$navbar-light-disabled-color:        $gray-500 !default;
$navbar-light-toggler-border-color:  null !default;

$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;
$navbar-dark-brand-color:           inherit !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;
// scss-docs-end navbar-theme-variables

// Boosted mod
$navbar-toggler-icon-bg:       $burger-icon !default;
$navbar-toggler-icon-close-bg: $cross-icon !default;
// End mod

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                $spacer * .5 !default;
$dropdown-padding-y:                0 !default;
$dropdown-spacer:                   $border-width * -1 !default;
$dropdown-font-size:                $font-size-base !default;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             $gray-500 !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      0 !default;
$dropdown-divider-bg:               $dropdown-border-color !default;
$dropdown-divider-margin-y:         $spacer * .25 !default;
$dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color:               $black !default; // Boosted mod
$dropdown-link-hover-color:         $white !default; // Boosted mod
$dropdown-link-hover-bg:            $black !default; // Boosted mod

$dropdown-link-active-color:        color-contrast($component-active-color) !default; // Boosted mod
$dropdown-link-active-bg:           $component-active-color !default;  // Boosted mod

$dropdown-link-disabled-color:      $gray-500 !default;

$dropdown-item-padding-y:           $spacer * .5 !default;
$dropdown-item-padding-x:           $spacer * .5 !default;

$dropdown-header-color:             $black !default;
$dropdown-header-padding:           $spacer $spacer * .5 !default;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:               $gray-300 !default;
$dropdown-dark-bg:                  $gray-800 !default;
$dropdown-dark-border-color:        $dropdown-border-color !default;
$dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:        $gray-500 !default;
// scss-docs-end dropdown-dark-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              null !default;
$pagination-padding-x:              null !default;

$pagination-color:                  null !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           $border-width !default;
$pagination-border-radius:          null !default;
$pagination-margin-y:               $spacer !default; // Boosted mod
$pagination-margin-start:           $spacer * .5 !default;
$pagination-border-color:           transparent !default;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-color:            $link-color !default;
$pagination-focus-bg:               null !default;
$pagination-focus-outline:          $pagination-border-width solid $pagination-focus-color !default;

$pagination-hover-color:            $link-color !default;
$pagination-hover-bg:               null !default;
$pagination-hover-border-color:     $gray-500 !default;

$pagination-active-color:           $white !default;
$pagination-active-bg:              $black !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $gray-500 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $pagination-disabled-color !default;

$pagination-transition:             $transition-focus !default;

// Boosted mod
$pagination-padding-end:            1.125rem !default;
$pagination-icon:                   var(--#{$boosted-variable-prefix}chevron-icon) !default;
$pagination-icon-size:              subtract($spacer * 2, $border-width * 2) !default;
$pagination-icon-width:             add(.5rem, 1px) !default;
$pagination-icon-height:            subtract(1rem, 1px) !default;
$pagination-active-item-bg:         $primary !default;
$pagination-active-item-border:     $pagination-active-item-bg !default;
// End mod
// scss-docs-end pagination-variables


// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max:           .5 !default;
$placeholder-opacity-min:           .2 !default;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer !default;
$card-spacer-x:                     $spacer !default;
$card-title-spacer-y:               $spacer * .5 !default;
$card-border-width:                 $border-width !default;
$card-border-color:                 $gray-500 !default; // Boosted mod
$card-border-radius:                $border-radius !default;
$card-box-shadow:                   null !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                $card-spacer-y * .5 !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       $black !default;
$card-cap-color:                    $white !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;
$card-img-overlay-padding:          $spacer !default;
$card-group-margin:                 $grid-gutter-width * .5 !default;
$card-footer-color:                 $gray-700 !default; // Boosted mod
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     $spacer * .5 !default;
$accordion-padding-x:                     $spacer * .5 !default;
$accordion-color:                         $body-color !default;
$accordion-bg:                            $body-bg !default;
$accordion-border-width:                  $border-width * .5 !default;
$accordion-border-color:                  $gray-500 !default;
$accordion-border-radius:                 $border-radius !default;
$accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y:                $spacer !default;
$accordion-body-padding-x:                $spacer !default;

$accordion-button-padding-y:              $accordion-padding-y !default;
$accordion-button-padding-x:              $accordion-padding-x !default;
$accordion-button-color:                  $accordion-color !default;
$accordion-button-bg:                     $accordion-bg !default;
$accordion-transition:                    $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:              null !default;
$accordion-button-active-color:           $accessible-orange !default;

// Boosted mod: no $accordion-button-focus-*

// Boosted mod: use caret
$accordion-icon-transform:                scaleY(-1) !default;

// Boosted mod: accordion sizes
$accordion-button-font-size:              $h3-font-size !default;
$accordion-button-line-height:            null !default;
$accordion-button-font-weight:            $font-weight-bold !default;
$accordion-button-letter-spacing:         $h3-spacing !default;
$accordion-button-font-size-sm:           $h5-font-size !default;
$accordion-button-line-height-sm:         $h5-line-height !default;
$accordion-button-letter-spacing-sm:      $h5-spacing !default;
$accordion-button-font-size-lg:           $h2-font-size !default;
$accordion-button-line-height-lg:         calc(40 / 30) !default; // stylelint-disable-line function-disallowed-list
$accordion-button-letter-spacing-lg:      $h2-spacing !default;
// End mod
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 $font-size-sm !default;
$tooltip-font-weight:               $font-weight-bold !default; // Boosted mod
$tooltip-max-width:                 $spacer * 10 !default;
$tooltip-color:                     $black !default;
$tooltip-bg:                        $gray-400 !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 $spacer * .5 !default;
$tooltip-padding-x:                 $spacer * .5 !default;
$tooltip-margin:                    $spacer * .25 !default;

$tooltip-arrow-width:               $spacer * .5 !default;
$tooltip-arrow-height:              $tooltip-arrow-width * .5 !default;
$tooltip-arrow-color:               $tooltip-bg !default;
// scss-docs-end tooltip-variables

// Boosted mod: no form tooltips


// Popovers

// scss-docs-start popover-variables
$popover-font-size:                 $font-size-sm !default;
$popover-font-weight:               $font-weight-bold !default; // Boosted mod
$popover-bg:                        $gray-400 !default;
$popover-max-width:                 17.25rem !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              $popover-bg !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                null !default;

$popover-header-bg:                 $popover-bg !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          $spacer * .5 !default;
$popover-header-padding-x:          $spacer * .5 !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               $spacer * .5 !default;
$popover-arrow-height:              $popover-arrow-width * .5 !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         $popover-border-color !default;
// scss-docs-end popover-variables

// Toasts

// scss-docs-start toast-variables
$toast-max-width:                   21.875rem !default;
$toast-padding-x:                   $spacer * .5 !default;
$toast-padding-y:                   $spacer * .25 !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       $black !default;
$toast-background-color:            rgba($white, .85) !default;
$toast-border-width:                1px !default;
$toast-border-color:                rgba(0, 0, 0, .1) !default;
$toast-border-radius:               $border-radius !default;
$toast-box-shadow:                  $box-shadow !default;
$toast-spacing:                     $container-padding-x !default;

$toast-header-color:                null !default;
$toast-header-background-color:     rgba($white, .85) !default;
$toast-header-border-color:         rgba(0, 0, 0, .05) !default;
// scss-docs-end toast-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .75em !default;
$badge-font-weight:                 $font-weight-bold !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .35em !default;
$badge-padding-x:                   .65em !default;
$badge-border-radius:               $border-radius !default;
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding:               $spacer * .5 $spacer !default;

$modal-footer-margin-between:       $spacer * .5 !default;
$modal-footer-padding:              $spacer * .5 subtract($spacer, $modal-footer-margin-between * .5) 0 !default; // Boosted mod

$modal-dialog-margin:               $spacer * .5 !default;
$modal-dialog-margin-y-sm-up:       $spacer * 1.5 !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-padding-y:           $spacer !default; // Boosted mod
$modal-content-padding-x:           0 !default; // Boosted mod
$modal-content-padding:             $modal-content-padding-y $modal-content-padding-x !default; // Boosted mod
$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        $gray-500 !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: $border-radius !default;
$modal-content-box-shadow-xs:       $box-shadow-sm !default;
$modal-content-box-shadow-sm-up:    $box-shadow !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
$modal-header-border-color:         null !default;
$modal-footer-border-color:         null !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            0 !default;
$modal-header-padding-x:            $spacer !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// Boosted mod
//// Scrollable modal
$modal-scrollable-inner-padding:    $spacer !default;
$modal-scrollable-inner-margin:     $spacer 0 $spacer * .5 !default;

//// Modal with top image
$modal-img-margin:                  -$modal-content-padding-y 0 $modal-content-padding-y !default; // Boosted mod
$modal-img-btn-close-offset:        $modal-content-padding-y !default;
// End mod

$modal-sm:                          300px !default;
$modal-md:                          460px !default;
$modal-lg:                          700px !default;
$modal-xl:                          940px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;
// scss-docs-end modal-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y:                   1rem !default;
$alert-padding-x:                   $spacer !default;
$alert-margin-bottom:               $spacer !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            null !default;
$alert-border-width:                $border-width !default;

// Boosted mod
$alert-padding-sm:                  $spacer * .5 !default;
$alert-colors:                      map-remove($theme-colors, "primary", "secondary", "light", "dark") !default;
$alert-icons: (
  "success": var(--#{$boosted-variable-prefix}success-icon),
  "info":    escape-svg($info-icon),
  "warning": escape-svg($warning-icon),
  "danger":  var(--#{$boosted-variable-prefix}error-icon)
) !default;
$alert-logo-size:                   add($spacer * .5, 1rem) !default;
$alert-logo-size-sm:                add(1rem, 1px) !default;
$alert-icon-size:                   3rem !default;
$alert-icon-margin-y:               $spacer * .1 !default;
$alert-btn-close-offset:            .5rem !default;
$alert-btn-close-offset-sm:         $spacer * .25 !default;
// End mod

$alert-dismissible-padding-r:       $alert-padding-y * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// Progress bars

// scss-docs-start progress-variables
$progress-height:                   $spacer !default;
$progress-font-size:                $font-size-base !default;
$progress-bg:                       $gray-300 !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               $box-shadow-inset !default;
$progress-bar-color:                $black !default;
$progress-bar-bg:                   $primary !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;

// Boosted mod
$progress-height-sm:                $spacer * .5 !default;
$progress-height-xs:                $spacer * .25 !default;
// End mod
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
$list-group-color:                  $gray-900 !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           $gray-500 !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         .875rem !default;
$list-group-item-padding-x:         $spacer !default;

$list-group-active-color:           $white !default;
$list-group-active-bg:              $black !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-500 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $black !default;
$list-group-action-hover-color:     $primary !default;
// scss-docs-end list-group-variables


// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding:                 $spacer * .5 !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            $black !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              $box-shadow-sm !default;
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
$figure-caption-font-size:          $small-font-size !default;
$figure-caption-color:              $gray-900 !default;
// scss-docs-end figure-variables


// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              $font-size-sm !default;

$breadcrumb-padding-y:              .5rem !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         $spacer * .5 !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          null !default;
$breadcrumb-active-color:           null !default;
$breadcrumb-divider:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14' width='7' height='10'><path d='M-.4 12l2 2 7-7-7-7-2 2 5 5z'/></svg>") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;
// scss-docs-end breadcrumb-variables


// Carousel

// scss-docs-start carousel-variables
$carousel-control-color:             $black !default;
$carousel-control-width:             $spacer * 3 !default;
$carousel-control-opacity:           null !default;
$carousel-control-hover-opacity:     null !default;
$carousel-control-transition:        $transition-focus !default;

$carousel-indicator-width:           .5rem !default;
$carousel-indicator-height:          .5rem !default;
$carousel-indicator-hit-area-height: $spacer * 1.5 !default;
$carousel-indicator-spacer:          $spacer * .5 !default;
$carousel-indicator-opacity:         null !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-active-opacity:  null !default;
$carousel-indicator-transition:      null !default;
// Boosted mod
$carousel-indicator-hover-scale:        1.5 !default;
$carousel-indicator-active-scale:       calc(2 / 3) !default; // stylelint-disable-line function-disallowed-list
$carousel-indicator-active-radius:      0 100% 100% 0 / 50% !default;
$carousel-indicator-animation-duration: 5000ms !default;
$carousel-indicator-animation-interval: var(--carousel-interval, #{$carousel-indicator-animation-duration}) !default;
$carousel-indicators-padding-y:         $spacer * .5 !default;
// End mod

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $black !default;
$carousel-caption-padding-y:         $spacer !default;
$carousel-caption-padding-x:         $spacer !default; // Boosted mod
$carousel-caption-spacer:            $spacer * 3 !default;

$carousel-control-icon-width:        2.5rem !default;
// Boosted mod
$carousel-control-icon-size:         1rem 1.5rem !default;
$carousel-control-icon-bg:           var(--#{$boosted-variable-prefix}chevron-icon) !default;
$carousel-control-icon-active-bg:    $component-active-bg !default;
// End mod

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration $transition-timing !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Boosted mod: no dark carousel
// scss-docs-end carousel-variables


// Spinners

// scss-docs-start spinner-variables
$spinner-width:           $spacer * 2 !default;
$spinner-height:          $spinner-width !default;
$spinner-vertical-align:  -.125em !default;
$spinner-border-width:    $border-width * 3 !default;
$spinner-animation-speed: .75s !default;

$spinner-width-sm:        $spacer !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: $border-width * 2 !default;

// Boosted mod
$spinner-width-lg:        $spacer * 4 !default;
$spinner-height-lg:       $spinner-width-lg !default;
$spinner-border-width-lg: $border-width * 4 !default;
// End mod
// scss-docs-end spinner-variables


// Close

// scss-docs-start close-variables
$btn-close-width:            $spacer !default;
$btn-close-height:           $btn-close-width !default;
$btn-close-padding:          var(--#{$boosted-variable-prefix}icon-spacing, #{$btn-icon-padding-x}) !default;
$btn-close-color:            $black !default;
$btn-close-bg:               var(--#{$boosted-variable-prefix}close-icon) !default;
$btn-close-focus-shadow:     $btn-focus-box-shadow !default;

// Boosted mod
$btn-close-icon-size:        1rem auto !default;
$btn-close-padding-sm:       subtract($btn-icon-padding-x, $spacer * .25) !default;
// End mod
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               $modal-inner-padding !default;
$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        400px !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
$offcanvas-border-color:            $modal-content-border-color !default;
$offcanvas-border-width:            $modal-content-border-width !default;
$offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                $modal-content-bg !default;
$offcanvas-color:                   $modal-content-color !default;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables

// Code

$code-font-size:                    .875em !default;
$code-color:                        $gray-700 !default;

$kbd-padding-y:                     $spacer * .05 !default;
$kbd-padding-x:                     $spacer * .05 !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         $black !default;
$kbd-bg:                            $gray-300 !default;

$pre-color:                         $gray-900 !default;
$pre-line-height:                   1.25 !default; // Boosted mod

//
// Boosted mod
//

//// Scroll margin
$scroll-offset-top:              $spacer * 6 !default; // Matching .navbar computed height

//// Back to top
// scss-docs-start back-to-top
$back-to-top-display-threshold:  100vh !default;
$back-to-top-target-id:          "top" !default;
$back-to-top-target-offset-top:  $scroll-offset-top !default;
$back-to-top-offset:             $spacer * 1.5 !default;
$back-to-top-offset-right:       $back-to-top-offset !default;
$back-to-top-offset-bottom:      $back-to-top-offset !default;
$back-to-top-link-offset-top:    subtract(100vh, $back-to-top-offset * 4) !default;
$back-to-top-link-offset-top-xl: subtract(100vh, $spacer * 5) !default;
$back-to-top-title-offset-right: add(100%, $border-width) !default;
$back-to-top-title-padding:      subtract($btn-padding-y, 1px) $btn-padding-x add($btn-padding-y, 1px) !default;
$back-to-top-title-bg-color:     $white !default;
$back-to-top-icon:               var(--#{$boosted-variable-prefix}chevron-icon) !default;
$back-to-top-icon-width:         add(.5rem, 1px) !default;
$back-to-top-icon-height:        subtract(1rem, 1px) !default;
// scss-docs-end back-to-top

//// Stepped process
// scss-docs-start stepped-process
$stepped-process-max-items: 5 !default;
$stepped-process-counter:   step !default; // Used as a counter name

$step-item-margin-end:   $border-width !default;
$step-item-padding:      .5rem !default;
$step-item-bg:           $black !default;
$step-item-active-bg:    $primary !default;
$step-item-next-bg:      $gray-400 !default;
$step-item-shadow-size:  $border-width * 1.5 !default;
$step-item-drop-shadow:  drop-shadow($step-item-shadow-size 0 0 $white) #{"/* rtl:"} drop-shadow(-$step-item-shadow-size 0 0 $white) #{"*/"} !default;
$step-item-padding-end:  $step-item-padding * 2 !default;

$step-link-width:        1.25ch !default; // Matches width of a single number
$step-link-color:        $white !default;
$step-link-active-color: $black !default;
$step-link-marker:       counter($stepped-process-counter) "\A0" !default;
$step-link-marker-lg:    counter($stepped-process-counter) ".\A0" !default;

$step-item-arrow-width:  1rem !default;
$step-item-arrow-shape:  polygon(0% 0%, subtract(100%, $border-width) 50%, 0% 100%) #{"/* rtl:"} polygon(100% 0%, $border-width 50%, 100% 100%) #{"*/"} !default; // Used in clip-path
// scss-docs-end stepped-process
// End mod
