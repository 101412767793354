//
// Base styles
//

.btn {
  display: inline-flex; // Boosted mod
  align-items: center; // Boosted mod: for .badge proper alignment
  justify-content: center; // Boosted mod
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $body-color;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  outline-offset: map-get($spacers, 1); // Boosted mod
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  @include transition($btn-transition);

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  // Boosted mod: custom styles for .btn-check
  &:focus {
    // Boosted mod
    outline-offset: $outline-width;

    &[data-focus-visible-added] {
      box-shadow: $btn-focus-box-shadow;
    }
    // End mod
  }

  // Boosted mod: custom styles for .btn-check
  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Boosted mod: with icon
  > svg {
    transform: translateY(1px);
  }
  // End mod
}


//
// Alternate buttons
//

// Boosted mod: inconsistent theming ¯\_(ツ)_/¯
// scss-docs-start btn-variant-loops
.btn-primary,
.btn-warning {
  @include button-variant($primary, $primary, $active-background: $white, $active-border: $black);
}

.btn-light,
.btn-secondary {
  @include button-variant($white, $black, $disabled-background: $white, $disabled-border: $gray-500, $disabled-color: $gray-500);
}

.btn-success {
  @include button-variant($success, $success);
}

.btn-info,
.btn-dark {
  @include button-variant($black, $black, $white, $white, $black, $black);
}

.btn-danger {
  @include button-variant($danger, $danger);
}
// scss-docs-end btn-variant-loops
// End mod

// Boosted mod: no .btn-outline

// Boosted mod: border-only on :hover, :focus and :active
.btn-no-outline {
  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $gray-500;
  }

  &:active,
  &.active {
    color: $accessible-orange;
    background-color: transparent;
  }

  &:disabled,
  &.disabled {
    color: $gray-500;
  }
}
// End mod


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  color: $btn-link-color;
  text-decoration: $link-decoration;
  // stylelint-disable-next-line declaration-no-important
  border-color: transparent !important; // Boosted mod

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
// Boosted mod: 6th argument to set .btn-icon padding

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg, $btn-line-height-lg, $btn-icon-padding-x-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm, $btn-line-height-sm, $btn-icon-padding-x-sm);
}


//
// Boosted mod: icon button
//
.btn-icon {
  padding-right: var(--#{$boosted-variable-prefix}icon-spacing);
  padding-left: var(--#{$boosted-variable-prefix}icon-spacing);
}

.btn-social {
  border-color: currentColor;
  @include border-radius(50%, 50%);
  @include button-icon(var(--#{$boosted-variable-prefix}network-logo));

  &:hover,
  &:focus {
    color: $white;
    background-color: var(--#{$boosted-variable-prefix}network-color, $black);
    border-color: var(--#{$boosted-variable-prefix}network-color, $black);
    outline-color: var(--#{$boosted-variable-prefix}network-color, $black);
  }

  &:active,
  &.active {
    color: $white;
    background-color: $black;
    border-color: $black;
  }

  &::before {
    transform: translateY(1px);
  }
}

@each $name in map-keys($btn-social-networks) {
  $network: map-get($btn-social-networks, $name);

  .btn-#{$name} {
    --#{$boosted-variable-prefix}network-color: #{map-get($network, "color")};
    --#{$boosted-variable-prefix}network-logo: #{escape-svg(url("data:image/svg+xml,#{map-get($network, "icon")}"))};

    &::before {
      mask-size: if(map-has-key($network, "size"), map-get($network, "size"), null);

      @if "linkedin" == $name {
        transform: none;
      }
    }
  }
}
