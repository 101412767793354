// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: subtract($badge-padding-y, 1px) $badge-padding-x add($badge-padding-y, 1px); // Boosted mod
  @include font-size($badge-font-size);
  font-weight: $badge-font-weight;
  line-height: 1;
  color: $badge-color;
  text-align: center;

  /* rtl:remove */
  letter-spacing: initial; // Boosted mod
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
  @include gradient-bg();

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  padding: $badge-padding-y $badge-padding-x; // Boosted mod
  margin-left: map-get($spacers, 1); // Boosted mod
}

// Boosted mod: inconsistent background color & naming
@each $color, $value in $theme-colors {
  .badge.bg-#{$color} {
    color: color-contrast($value);
    background-color: $value !important; // stylelint-disable-line declaration-no-important
  }
}
