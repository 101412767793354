@each $color, $value in ("primary": $accessible-orange, "light": $light) { // Boosted mod: restrict to primary and light
  .link-#{$color} {
    color: $value;
    background-color: color-contrast($value); // Boosted mod: ensure contrast

    @if $link-shade-percentage != 0 {
      &:hover,
      &:focus {
        color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage));
      }
    }
  }
}
