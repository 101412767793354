// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// scss-docs-start btn-variant-mixin
@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: $black, // Boosted mod
  $hover-border: $black, // Boosted mod
  $hover-color: color-contrast($hover-background),
  $active-background: $primary, // Boosted mod
  $active-border: $primary, // Boosted mod
  $active-color: color-contrast($active-background),
  $disabled-background: $gray-500, // Boosted mod
  $disabled-border: $gray-500, // Boosted mod
  $disabled-color: $white // Boosted mod
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  // Boosted mod: custom styles for .btn-check
  &:focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    outline-color: $hover-border; // Boosted mod
    // Boosted mod: buttons share the same `box-shadow` on `:focus`
  }

  // Boosted mod: custom styles for .btn-check
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;
    outline-color: $active-border; // Boosted mod

    // Boosted mod: buttons share the same `box-shadow` on `:focus`
  }

  &:disabled,
  &.disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;
  }
}
// scss-docs-end btn-variant-mixin

// Boosted mod: no .btn-outline

// scss-docs-start btn-size-mixin
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius, $line-height: null, $icon-spacing: $btn-icon-padding-x) {
  --#{$boosted-variable-prefix}icon-spacing: #{$icon-spacing}; // Boosted mod: used for icons
  padding: subtract($padding-y, 1px) $padding-x add($padding-y, 1px);
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}
// scss-docs-end btn-size-mixin

// Boosted mod: embed an icon as mask-image in a button
// scss-docs-start btn-icon
@mixin button-icon(
  $icon,
  $width: $spacer,
  $height: $width,
  $size: $width $height,
  $pseudo: "before",
  $selector: &,
  $position: 50%
) {
  min-width: $width;
  min-height: $height;

  @at-root #{$selector} {
    &::#{$pseudo} {
      display: block;
      min-width: inherit;
      min-height: inherit;
      content: "";
      background-color: currentColor;
      -webkit-mask: #{$icon} no-repeat #{$position} / #{$size}; // stylelint-disable-line property-no-vendor-prefix
      mask: #{$icon} no-repeat #{$position} / #{$size};
    }
  }
}
// scss-docs-end btn-icon
