// Boosted only
// See https://moderncss.dev/pure-css-smooth-scroll-back-to-top/
@if $enable-fixed-header {
  [id="#{$back-to-top-target-id}"]:target {
    scroll-margin-top: $back-to-top-target-offset-top;
  }
}

.back-to-top {
  position: absolute;
  top: $back-to-top-display-threshold;
  right: $back-to-top-offset-right;
  bottom: $back-to-top-offset-bottom;
  z-index: $zindex-back-to-top;
  pointer-events: none;

  @include media-breakpoint-up(xl) {
    right: $back-to-top-offset-right * 2;
    bottom: $back-to-top-offset-bottom * 2;
  }
}

.back-to-top-link {
  position: sticky;
  top: $back-to-top-link-offset-top;
  pointer-events: all;
  @include button-icon($back-to-top-icon, $size: $back-to-top-icon-width $back-to-top-icon-height, $pseudo: "after");

  &::after {
    transform: rotate(.25turn) #{"/* rtl:ignore */"};
  }

  &:not([title]):not([data-#{$boosted-variable-prefix}label])::after {
    margin-left: $spacer * .5;
  }

  @include media-breakpoint-up(xl) {
    top: $back-to-top-link-offset-top-xl;

    &[data-#{$boosted-variable-prefix}label]::before {
      position: absolute;
      right: $back-to-top-title-offset-right;
      z-index: -1; // To ensure focus indicator appears above
      padding: $back-to-top-title-padding;
      color: color-contrast($back-to-top-title-bg-color);
      white-space: nowrap;
      content: attr(data-#{$boosted-variable-prefix}label);
      background-color: $back-to-top-title-bg-color;
      transform: translateY(1px);
    }

    &[data-#{$boosted-variable-prefix}label]:hover::before,
    &[data-#{$boosted-variable-prefix}label]:focus::before {
      text-decoration: $link-decoration;
    }
  }
}
