//
// Base styles
//

.alert {
  position: relative;
  display: flex; // Boosted mod
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  font-weight: $font-weight-bold; // Boosted mod
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
  // Boosted mod: ensure headings look like paragraphs
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  // End mod
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: $alert-btn-close-offset; // Boosted mod
    right: $alert-btn-close-offset; // Boosted mod
    z-index: $stretched-link-z-index + 1;
  }
}

//
// Boosted mod
//

//// Only border changes — and we add a Solaris icon

// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.
@each $color, $value in $alert-colors {
  .alert-#{$color} {
    border-color: $value;

    .alert-icon::before {
      background-image: map-get($alert-icons, $color);
    }
  }
}
// scss-docs-end alert-modifiers

//// Additional content
.alert * p {
  margin: 0;
}

.alert-heading ~ p {
  font-weight: $font-weight-normal;
}

//// Icons
.alert-icon {
  flex-shrink: 0;
  width: $alert-icon-size;
  margin: -#{$alert-icon-margin-y * .5} 0;

  + * {
    margin: $alert-icon-margin-y 0;
  }

  &::before {
    display: block;
    order: -1;
    height: $alert-logo-size;
    content: "";
    background-repeat: no-repeat;

    /* rtl:raw:
    background-position: top right;
    */
    background-size: $alert-logo-size;
  }
}

//// Small alerts
.alert-sm {
  padding: $alert-padding-sm 0;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  border: 0;

  .alert-icon {
    width: $alert-icon-size * .5;
    margin: $alert-icon-margin-y 0;

    &::before {
      height: $alert-logo-size-sm;
      background-size: $alert-logo-size-sm;
    }
  }

  .btn-close {
    --#{$boosted-variable-prefix}icon-spacing: #{$btn-close-padding-sm};
    top: $alert-btn-close-offset-sm;
    right: -$alert-btn-close-offset-sm;
  }
}
// End mod
