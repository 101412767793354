// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
  padding: $btn-close-padding;
  color: $btn-close-color;
  background-color: transparent; // include transparent for button elements
  border: $btn-border-width solid transparent; // Boosted mod
  outline-offset: map-get($spacers, 2); // Boosted mod
  @include border-radius();
  @include button-icon($btn-close-bg, $btn-close-width, $btn-close-height, $btn-close-icon-size); // Boosted mod: using mask instead of background

  // Override <a>'s hover style
  &:hover {
    color: $btn-close-color;
    text-decoration: none;
  }

  // Boosted mod: matching .btn-no-outline
  &:hover,
  &:focus,
  &:active {
    border-color: $gray-500;
  }

  &:focus {
    outline-offset: $outline-width;
    @include transition($transition-focus);

    &[data-focus-visible-added] {
      box-shadow: $btn-close-focus-shadow;
    }
  }

  &:active {
    color: $primary;
  }
  // End mod

  &:disabled,
  &.disabled {
    color: $gray-500; // Boosted mod
    pointer-events: none;
    user-select: none;
  }
}

// Boosted mod: changing color instead of using filter
.btn-close-white {
  &,
  &:hover:not(:active) {
    color: $white;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $gray-700;
  }

  &:disabled,
  &.disabled {
    color: $gray-700;
  }
}
// End mod
